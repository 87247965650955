<template>
  <div>
    <v-card>
      <v-card-title
        >Movements
        <v-spacer></v-spacer>
        <v-btn @click="isFormOpen = true">New Movement</v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="movements">
        <template #[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="isFormOpen" max-width="600px" persistent>
      <v-card>
        <v-card-title>New Movement</v-card-title>
        <v-card-text>
          <v-text-field
            label="Movement Name"
            v-model="newMovement.name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isFormOpen = false" text>close</v-btn>
          <v-btn @click="saveMovement" text color="primary">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import repprApi from "../services/repprApi";
export default {
  async created() {
    this.movements = await repprApi.getMovements();
  },
  data() {
    return {
      isFormOpen: false,
      newMovement: {
        name: "",
      },
      movements: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created At", value: "createdAt" },
      ],
    };
  },
  methods: {
    async saveMovement() {
      console.log("save movement");
      const movement = await repprApi.createMovement(this.newMovement);
      this.movements = [...this.movements, movement];
      this.newMovement.name = "";
      this.isFormOpen = false;
    },
  },
};
</script>